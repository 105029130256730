import { FunctionalComponent, h } from '@stencil/core';
import { host } from '../shared/config'
import get from 'lodash/get'

interface Thumbnail {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    width: number;
    height: number;
    size: number;
    path?: any;
    url: string;
}

interface Large {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    width: number;
    height: number;
    size: number;
    path?: any;
    url: string;
}

interface Medium {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    width: number;
    height: number;
    size: number;
    path?: any;
    url: string;
}

interface Small {
    name: string;
    hash: string;
    ext: string;
    mime: string;
    width: number;
    height: number;
    size: number;
    path?: any;
    url: string;
}

interface Formats {
    thumbnail: Thumbnail;
    large: Large;
    medium: Medium;
    small: Small;
}

interface Media {
    id: number;
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: Formats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl?: any;
    provider: string;
    provider_metadata?: any;
    created_at: Date;
    updated_at: Date;
}

interface iMedia {
    media: Media
    height?: string
    width?: string
    alt?: string
    className?: string
    cover?: boolean
    autoplay?: string
    size?: string
    eager?: boolean
    thumbnail?: boolean
}

export const AppMedia: FunctionalComponent<iMedia> = ({ media, height, width, alt, className, cover, autoplay, size, eager, thumbnail }) => {

    if (!media) return;
    const data = get(media, 'data.attributes') || get(media, 'attributes') || media
    if (!data) return;
    let { mime, alternativeText } = data
    const isCover = cover ? { 'uk-cover': true } : {};

    let url, srcset, sizes;
    url = get(data, 'url');

    if (['image/png', 'image/jpeg', 'image.webp'].includes(mime) && !thumbnail) {
        // uikit responsive images
        const urlOptions = [];
        if (get(data, 'formats.thumbnail.url')) urlOptions.push(`${host}${data.formats.thumbnail.url} ${data.formats.thumbnail.width}w`)
        if (get(data, 'formats.small.url')) urlOptions.push(`${host}${data.formats.small.url} ${data.formats.small.width}w`)
        if (get(data, 'formats.medium.url')) urlOptions.push(`${host}${data.formats.medium.url} ${data.formats.medium.width}w`)
        if (get(data, 'formats.large.url')) urlOptions.push(`${host}${data.formats.large.url} ${data.formats.large.width}w`)
        if (get(data, 'url')) urlOptions.push(`${host}${data.url} ${data.width}w`)
        srcset = urlOptions.join(', ')
        sizes = size || "(max-width: 638px) 90vh, (max-width: 958px) 100vw, 100vw"
    }
    if (thumbnail) url = get(data, 'formats.thumbnail.url');

    if (!mime || !url) return;
    const attrs = {}
    if (autoplay) attrs['autoplay'] = true;

    const newHeight = height || get(data, 'height');
    const newWidth = width || get(data, 'width');

    switch (mime) {
        case 'video/mp4': return <video height={height} width={width} class={className} src={`${host}${url}`} autoplay={autoplay} muted playsinline controls={false} loop preload='metadata' {...attrs} {...isCover}></video>
        case 'image/webp': return <img height={newHeight} width={newWidth} class={className} alt={alt || alternativeText} src={`${host}${url}`} srcset={srcset} sizes={sizes} loading={eager ? 'eager' : 'lazy'} {...isCover}></img>
        case 'image/png': return <img height={newHeight} width={newWidth} class={className} alt={alt || alternativeText} src={`${host}${url}`} srcset={srcset} sizes={sizes} loading={eager ? 'eager' : 'lazy'} {...isCover}></img>
        case 'image/jpeg': return <img height={newHeight} width={newWidth} class={className} alt={alt || alternativeText} src={`${host}${url}`} srcset={srcset} sizes={sizes} loading={eager ? 'eager' : 'lazy'} {...isCover}></img>
        case 'image/svg+xml': return <img height={height} width={width} class={`uk-preserve ${className}`} src={`${host}${url}`} aria-label={alt} uk-svg {...isCover} />
    }
};